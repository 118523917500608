/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
	font-family: 'Helvetica';
	src: url(./Fonts/HelveticaNeueMedium.otf);
}
@font-face {
	font-family: 'Helvetica-Heavy';
	src: url(./Fonts/HelveticaNeueHeavy.otf);
}
@font-face {
	font-family: 'Helvetica-Light';
	src: url(./Fonts/HelveticaNeueLight.otf);
}
@font-face {
	font-family: 'Helvetica-Text';
	src: url(./Fonts/HelveticaNeueLight.otf);
}

/* @font-face {
	font-family: 'Helvetica';
	font-weight: 900;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueHeavy.otf) format('opentype');
}

@font-face {
	font-family: 'Helvetica';
	font-weight: 800;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueHeavy.otf) format('opentype');
}
@font-face {
	font-family: 'Helvetica';
	font-weight: 700;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueBold.otf) format('opentype');
}
@font-face {
	font-family: 'Helvetica';
	font-weight: 400;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueThin.otf) format('opentype');
}
@font-face {
	font-family: 'Helvetica';
	font-weight: 300;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueLight.otf) format('opentype');
}
@font-face {
	font-family: 'Helvetica';
	font-weight: 200;
	src: local('Helvetica'), url(./Fonts/HelveticaNeueUltraLight.otf) format('opentype');
} */

.Heading {
	font-family: 'Helvetica-Heavy', sans-serif;
	font-weight: 800;
}

.Subtext {
	font-family: 'Helvetica-Light', sans-serif;
	font-weight: 200;
}

.Text {
	font-family: 'Helvetica', sans-serif;
	font-weight: 400;
}

body {
	background-color: #09002f;
}
.App {
	text-align: center;
	background-color: #09002f;
}

body {
	background-color: #09002f;
}

html {
	background-color: #09002f;
	overflow-x: hidden;
}
body::-webkit-scrollbar {
	display: none;
}
body {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}
